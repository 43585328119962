.trainer-img {
  object-fit: cover;
  height: 55vh;
}

@media (max-width: 767px) {
  .trainer-img {
    height: 30vh;
  }
}
