@media (max-width: 767px) {
  .mobile-none {
    display: none;
  }
}

@media (max-width: 516px) {
  .moblie-margin {
    margin-top: 1.5rem;
  }
}
